<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center" class="toolbar-toggle">
      <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="(authenticationService.clientPortal$ | async)?.access_type" class="client-portal-name">
      {{ (authenticationService.clientPortal$ | async).access_type === 'province' ? 'Provincie' : 'Gemeente' }}
      {{ (authenticationService.clientPortal$ | async).access.join(', ') }}
    </div>
  </div>

  <div fxLayout="row">

    <fury-toolbar-fullscreen-toggle class="toolbar-button"></fury-toolbar-fullscreen-toggle>

    <!--
    <fury-toolbar-notifications class="toolbar-button"></fury-toolbar-notifications>
    -->

    <fury-toolbar-user class="toolbar-button"></fury-toolbar-user>
  </div>
</div>

